/**
* hidden: true,               是否隐藏路由
* path: '/welcome',           路由地址
* component:'Layout',         vue文件路径
* redirect: noredirect,       路由重定向
* name:'welcome',             路由名称父级可不填
* meta : {                    单层级路由父级可不填
   role: ['admin','editor'],    页面权限
   title: 'title'               页面标题
   icon: 'svg-name'             页面图标
   breadcrumb: false,           如果设置为false，则不会在breadcrumb面包屑中显示(默认 true)
   keepAlive: false,            页面是否缓存
 }
**/

export default [
  // 首页
  {
    path: '/',
    redirect: '/home',
    component: 'Layout',
    meta: {
      title: '首页',
      icon: 'workplace',
    },
    children: [
      {
        path: '/home',
        name: 'Home',
        component: '/home/Index'
      }
    ]
  },
  // 集团管理
  {
    path: '/groupManage',
    component: 'Layout',
    redirect: '/groupManage/index',
    meta: {
      title: '集团管理',
      icon: 'components',
    },
    children: [
      {

        path: '/groupManage/index',
        name: 'groupManage',
        component: '/groupManage/Index',
        meta: {
          title: '集团管理',
        },
      },
      {
        path: '/companyManage/index',
        name: 'companyManage',
        component: '/companyManage/Index',
        meta: {
          title: '公司管理',
        },
      },
      {
        hidden: true,
        path: 'groupInfo',
        name: 'groupInfo',
        meta: {
          title: '集团详情',
        },
        component: '/groupManage/groupInfo/index',
      },
      {
        hidden: true,
        path: '/companyManage/companyInfo',
        name: 'companyInfo',
        meta: {
          title: '公司详情',
        },
        component: '/companyManage/companyInfo/index',
      }
    ]
  },
  // 公司管理
  // {
  //   path: '/companyManage',
  //   component: 'Layout',
  //   redirect: '/companyManage/index',
  //   meta: {
  //     title: '公司管理',
  //     icon: 'questionnaire',
  //   },
  //   children: [
  //     {
  //       path: '/companyManage/index',
  //       name: 'companyManage',
  //       component: '/companyManage/Index'
  //     },
  //     // {
  //     //   hidden: true,
  //     //   path: 'customerInfo',
  //     //   name: 'customerInfo',
  //     //   meta: {
  //     //     title: '公司详情',
  //     //   },
  //     //   component: '/groupManage/customerInfo/index',
  //     // }
  //   ]
  // },
  // 用户管理
  {
    path: '/user',
    component: 'Layout',
    redirect: '/user/index',
    meta: {
      title: '员工管理',
      icon: 'user',
    },
    children: [
      {
        path: '/user/index',
        name: 'user',
        component: '/userManage/Index'
      }
    ]
  },

]
