<template>
  <!-- 导航栏 -->
  <div class="navbar">
    <div class="left-menu flex align-center">
      <!-- 菜单折叠按钮 -->
      <div class="menu-unfold-outlined">
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="toggleSideBar"
        />
        <menu-fold-outlined v-else class="trigger" @click="toggleSideBar" />
      </div>
      <!-- 面包屑 -->
      <!-- <Breadcrumb class="breadcrumb-container ml-2"></Breadcrumb> -->
    </div>
    <div class="right-menu flex">
      <!-- 用户信息 -->
      <a-dropdown class="right-menu-item">
        <div class="avatar-wrapper cursor-pointer">
          <img
            :src="require('@/assets/images/user.png')"
            alt=""
            class="user-avatar"
          />
          <span>{{ userName }}</span>
        </div>
        <!-- 用户信息弹出框 -->
        <template #overlay>
          <a-menu>
            <a-menu-item key="0">
              <router-link to="/"> 回到主页 </router-link>
            </a-menu-item>
            <!-- <a-menu-item key="1">
              <router-link to="/personalSetting"> 个人中心 </router-link>
            </a-menu-item> -->

            <!-- <a-menu-item key="1">
              <a
                target="_blank"
                href="https://github.com/webdyc/vue3-antd-admin"
              >
                Github
              </a>
            </a-menu-item> -->
            <!-- <a-menu-item key="3">
              <a target="_blank" href="https://webdyc.com/"> 博客地址 </a>
            </a-menu-item> -->
            <a-menu-divider />
            <a-menu-item key="4" @click="logout">
              <div>退出登录</div>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb'
import { computed, reactive, toRefs } from '@vue/reactivity'
import { useStore } from 'vuex'
import {
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined
} from '@ant-design/icons-vue'

export default {
  name: 'Navbar',
  components: {
    Breadcrumb,
    DownOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined
  },
  setup() {
    const store = useStore()
    const state = reactive({
      ...store.state.user.info,
      lang: [
        { name: 'China', label: '简体中文', value: 'ch' },
        { name: 'English', label: 'English', value: 'en' }
      ],
      lang_current: 'ch'
    })
    // 获取菜单展开收起状态
    const collapsed = computed(() => {
      return store.getters.sidebar.opened
    })
    // 更改展开收起状态
    const toggleSideBar = async () => {
      store.dispatch('app/toggleSideBar')
    }
    // 登出
    const logout = async () => {
      await store.dispatch('user/FedLogOut')
      // 这个系统再切换用户时，路由不会动态更新。所以需要在登出函数中加上location.reload();刷新浏览器。
      location.reload()
    }
    return {
      ...toRefs(state),
      collapsed,
      toggleSideBar,
      logout
    }
  }
}
</script>
<style lang="scss" scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  .trigger {
    font-size: 20px;
    cursor: pointer;
    transition: color 0.3s;
    // margin-left: 16px;
  }
  .trigger:hover {
    color: #1890ff;
  }
  .right-menu {
    .right-menu-item {
      padding: 0 16px;
    }
    .avatar-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      .user-avatar {
        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 50px;
        margin-right: 8px;
      }
      .user-name {
        display: inline-block;
        align-content: center;
        align-items: center;
        height: 40px;
        margin-left: 6px;
        line-height: 40px;
        cursor: pointer;
      }
      .el-icon-caret-bottom,
      .el-icon-caret-top {
        cursor: pointer;
        position: absolute;
        right: -20px;
        // top: 25px;
        font-size: 12px;
      }
    }
  }
}
.current {
  color: #1890ff;
}
</style>
